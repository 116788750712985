export default [
  {
    title: '任务编码',
    width: 400,
    property: 'task_id',
    // showIcon: 'input',
  },
  {
    title: '备注',
    // width: 0,
    'min-width': 400,
    property: 'remark',
    showIcon: 'input',
  },
  {
    title: '状态',
    width: 200,
    property: 'status',
    showIcon: 'select',
  },
  {
    title: '创建用户',
    width: 200,
    property: 'create_user',
    showIcon: 'input',
  },
  {
    title: '创建时间',
    width: 200,
    property: 'create_time',
    sort: true,
    format: true,
    title: '完成时间',
    width: 200,
    property: 'end_time',
    sort: true,
    format: true,
  },
];
