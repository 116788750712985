export default [
  {
    title: '状态',
    property: 'status',
    value: [
      { code: 1, label: '排队中' },
      { code: 2, label: '执行中' },
      { code: 3, label: '已完成' },
      { code: 4, label: '任务失败' },
    ],
  },
];
