<template>
  <div class="doctor-match-warp">
    <!-- 头部区域 -->
    <div class="doctor-match-top">
      <div class="title">医生数据匹配</div>

      <div class="doctor-match-opt">
        <el-button type="primary" class="createBtn" @click="hanldeCreateMatch">新建匹配</el-button>

        <div class="doctor-match-opt-right">
          <div class="opt-times">
            <el-select
              v-model="matchListOptTime"
              clearable
              filterable
              placeholder="请选择"
              @change="handlechangeOptTimes"
            >
              <el-option
                v-for="item in optTimes"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </div>

          <el-button icon="el-icon-refresh-right" class="resetBtn" @click="init"></el-button>
        </div>
      </div>
    </div>

    <!-- 表格筛选区域 -->
    <div v-if="filterList.length > 0" class="datalist-title-filter">
      <div v-for="(item, index) in filterList" :key="index" class="datalist-title-filter-item">
        {{ getFlitersTitle(item.name) }}: {{ item.label }}
        <i class="el-icon-close" @click="deleteFilterItem(item.name)"></i>
      </div>
      <el-button
        v-if="filterList.length"
        class="datalist-title-button"
        type="text"
        @click="deleteAllFilter"
      >
        重置
      </el-button>
    </div>

    <!-- 列表主体区域 -->
    <div class="task_content">
      <eyao-table
        v-show="!requestData"
        ref="table"
        :table-data="tableData"
        :total-num="totalNum"
        @filter-change="filterChange"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        @sort-change="hanldeSortChange"
      >
        <el-table-column
          v-for="column in configList"
          :key="`${$route.name}-${column.property}`"
          :label="column.title"
          :prop="column.property"
          :width="column.width"
          :min-width="column['min-width']"
          :sortable="column.sort && 'custom'"
        >
          <template slot="header" slot-scope="scope">
            <!-- 表头 -->
            <eyao-table-search-header
              v-if="column.showIcon"
              :form-type="column.showIcon"
              :property="column.property"
              :title="column.title"
              :scope="scope"
              :options="valueOpts[column.property]"
              @search="searchOpts"
            ></eyao-table-search-header>
            <!-- 无icon -->
            <template v-else>{{ column.title }}</template>
          </template>

          <template slot-scope="scope">
            <!-- 涉及时间字段，format值 -->
            <template v-if="column.format">
              {{ scope.row[column.property] | getFormatTime }}
            </template>

            <!-- 状态列 添加icon -->
            <div v-else-if="column.property === 'status'" class="status-column">
              <i
                v-if="scope.row.status === '排队中'"
                class="status_flag"
                :style="{ color: '#FA9600', background: '#FA9600' }"
              />
              <i
                v-else-if="scope.row.status === '执行中'"
                class="status_flag"
                :style="{ color: '#1664FF', background: '#1664FF' }"
              />
              <i
                v-else-if="scope.row.status === '已完成'"
                class="status_flag"
                :style="{ color: '#04AF3E', background: '#04AF3E' }"
              />
              <i v-else class="status_flag" :style="{ color: '#E63F3F', background: '#E63F3F' }" />

              {{ scope.row[column.property] }}
            </div>

            <template v-else>{{ scope.row[column.property] }}</template>
          </template>
        </el-table-column>

        <!-- 操作列  -->
        <el-table-column width="120" label="操作" fixed="right">
          <div slot-scope="scope" class="opt-column">
            <div
              v-if="scope.row.status === '任务失败'"
              :style="{ color: '#1C2028' }"
              @click="handleViewLog(scope.$index, scope.row)"
            >
              失败原因
              <el-tooltip effect="dark" :content="scope.row.fail_reason" placement="top">
                <i class="el-icon-warning"></i>
              </el-tooltip>
            </div>

            <div
              v-if="scope.row.status === '已完成'"
              :style="{ color: '#0069FA' }"
              @click="gotoResult(scope.$index, scope.row)"
            >
              匹配结果
            </div>
          </div>
        </el-table-column>
        <template v-slot:empty>
          <div class="search-no-data" v-if="totalNum === 0 && !requestData">
            <img
              src="https://baike-med-1256891581.file.myqcloud.com/drugs_admin/20111202/6fd06465-d653-4f86-963e-cb5196e49caf.png"
              alt="未查询到相关数据"
            />
            未查询到相关数据
          </div>
        </template>
      </eyao-table>

      <!-- <div v-if="totalNum === 0 && !requestData" class="search-no-data">
        <img
          src="https://baike-med-1256891581.file.myqcloud.com/drugs_admin/20111202/6fd06465-d653-4f86-963e-cb5196e49caf.png"
          alt="未查询到相关数据"
        />
        未查询到相关数据
      </div> -->
    </div>

    <template v-if="requestData">
      <div class="listLoading">
        <img src="@/assets/loading.gif" alt="loading" />
      </div>
    </template>

    <!-- 新建医生匹配抽屉 -->
    <el-drawer
      title="新建医生匹配"
      :visible.sync="createMatchDrawer"
      direction="rtl"
      :size="480"
      class="doctorMatch-drawer-warp"
      :before-close="closeMatchDrawer"
    >
      <div class="doctorMatch-drawer-content">
        <div class="drawer-tips">
          <span>“所属机构名称”最多可查询100个，每个查询值不能少于4个中文字符</span>
        </div>

        <el-form :model="ruleForm" :rules="rules" ref="doctorMatchForm">
          <el-form-item label="所属机构名称" prop="drawer_type_name">
            <el-input
              type="textarea"
              v-model="ruleForm.drawer_type_name"
              placeholder="请填写查询值，回行可查询多个值"
              @input="hanldeCheckInput"
            />
          </el-form-item>

          <el-form-item label="标准科室" prop="drawer_standard_dept">
            <el-cascader
              v-model="ruleForm.drawer_standard_dept"
              ref="cascaderMallCatergory"
              :props="standardOpts"
              @change="changeStandard"
            ></el-cascader>
          </el-form-item>

          <el-form-item label="专业职称" prop="drawer_professional_title">
            <el-select
              v-model="ruleForm.drawer_professional_title"
              placeholder="请选择"
              multiple
              filterable
              @change="changeProfessional"
            >
              <el-option
                v-for="item in professionalOpts"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </el-form-item>

          <el-form-item label="备注" prop="drawer_remark">
            <el-input v-model="ruleForm.drawer_remark" placeholder="请填写备注信息" />
          </el-form-item>
        </el-form>

        <div class="doctorMatch-drawer-footer">
          <el-button
            slot="reference"
            v-auto-unfocus
            class="doctorMatch-drawer-button submitBtn"
            type="primary"
            @click="submitForm"
          >
            提交
          </el-button>

          <el-button class="doctorMatch-drawer-button cancelBtn" @click="closeMatchDrawer">
            取消
          </el-button>
        </div>
      </div>
    </el-drawer>
  </div>
</template>

<script>
import store from '@/store';
import config from './config';
import configLabel from './configLabel';
import eyaoTableSearchHeader from '@/components/table-search-header';
import eyaoTable from '@/components/table';
import * as apis from '@/api/data';
import { getLastMonth, getLastThreeMonths, dateFormat } from '@/utils/util';
// import papaparse from 'papaparse';

export default {
  name: 'DoctorMatch',
  components: {
    eyaoTableSearchHeader,
    eyaoTable,
  },
  filters: {
    getFormatTime(value) {
      if (value && !value.startsWith('0')) {
        return dateFormat(new Date(value), 'YYYY-MM-dd HH:mm');
      }
      return '';
    },
  },
  data() {
    return {
      activeTab: 0,
      // tab时间
      matchListOptTime: '近一个月',
      optTimes: [
        { label: '近一个月', value: 0 },
        { label: '近三个月', value: 1 },
      ],
      tabMonth: [],
      // 表格数据
      tableData: [],
      totalNum: 0,
      // 配置文件
      configList: [],
      configLabel: [],
      // 筛选条件
      valueOpts: {
        status: [
          { value: 1, label: '排队中' },
          { value: 2, label: '执行中' },
          { value: 3, label: '已完成' },
          { value: 4, label: '任务失败' },
        ],
      },
      filterObject: {},
      // 表头筛选条件显示的列表
      filterList: [],
      // 数据请求
      requestData: true,
      currentDataPage: 1,
      pageDataSize: 20,
      propertyName: ['status'],
      // 时间排序
      orderTime: {},
      // 抽屉
      createMatchDrawer: false,
      ruleForm: {
        drawer_type_name: '',
        drawer_standard_dept: '',
        drawer_professional_title: '',
        drawer_remark: '',
      },
      rules: {
        drawer_type_name: [{ required: true, message: '请填写所属机构名称', trigger: 'blur' }],
        drawer_standard_dept: [{ required: true, message: '请选择标准科室', trigger: 'change' }],
        drawer_remark: [{ required: true, message: '请填写备注', trigger: 'blur' }],
      },
      professionalOpts: [],
      standardOpts: {
        multiple: true,
        lazy: true,
        checkStrictly: true,
        lazyLoad(node, resolve) {
          const { level } = node;
          const nodes = [];
          const params = {
            code: level === 0 ? '' : node.value.split(',')[0],
            level: level.toString(),
          };
          if (level > 2) {
            return;
          }
          apis.GetStandardDeptList(params).then(res => {
            res.deps.forEach(item => {
              const obj = {
                value: `${item.code},${item.name}`,
                label: item.name,
                leaf: !item.level,
              };
              nodes.push(obj);
            });
            resolve(nodes);
          });
        },
      },
    };
  },
  computed: {
    // 角色权限判定
    role() {
      const newRole = JSON.parse(localStorage.getItem('role'));
      // 页面刷新时
      if (newRole) {
        this.$store.commit('user/updateRole', newRole);
      } else {
        return this.$store.state.user.role;
      }

      return newRole;
    },
    // 用户名
    userName() {
      const usr = JSON.parse(localStorage.getItem('userName'));
      if (!this.$store.state.user.userName && usr) {
        this.$store.commit('user/updateUserName', usr);
      } else {
        return this.$store.state.user.userName;
      }
      return usr;
    },
  },
  watch: {
    $route(to) {
      if (to.name === 'doctor-match') {
        this.init();
      }
    },
    role(newval) {
      if (newval === 3) {
        this.$message.error('无权限进入');
        this.$router.push('/dashboard/home');
      }
    },
  },
  mounted() {
    store.commit('user/setBreadcrumb', [{ name: '医生数据匹配', path: '/dashboard/doctor-match' }]);
    this.init();
    if (this.$route.query.task_id) {
      this.gotoResult();
    }
  },
  methods: {
    init() {
      this.tableData = [];
      this.requestData = true;
      this.configList = config;
      this.configLabel = configLabel;
      this.handlechangeOptTimes();
      this.getOptions();
    },

    handlechangeOptTimes(val = 0) {
      if (val === 0) {
        const oneMonth = getLastMonth();
        this.tabMonth = [oneMonth.last, oneMonth.now];
      } else {
        const threeMonth = getLastThreeMonths();
        this.tabMonth = [threeMonth.last, threeMonth.now];
      }
      console.log(this.tabMonth);
      this.filterInit();
    },

    // 筛选信息初始化
    filterInit() {
      this.filterObject = this.getStorage() || {};
      this.updateFilterList();
      this.GetDoctorMatchList();
    },

    // 每次通过table组件原生调整筛选时更新筛选条件信息
    filterChange(filter) {
      Object.keys(filter).forEach(key => {
        this.filterObject[key] = filter[key];
      });

      this.updateFilterList();
      this.GetDoctorMatchList();
    },

    updateFilterList() {
      const list = [];
      const { filterObject } = this;
      console.log(filterObject, '筛选filterObject');

      Object.keys(filterObject).forEach(key => {
        if (JSON.stringify(filterObject[key]) !== '{}') {
          list.push({ name: key, label: filterObject[key].label });
        }
      });
      this.filterList = list;
      console.log(this.filterList, '筛选条件list');
      this.setStorage();
    },

    // 展示筛选条件的标题
    getFlitersTitle(property) {
      let title = '';
      this.configList.forEach(item => {
        if (property === item.property) title = item.title;
      });
      return title;
    },

    // 筛选条件被手动删除时调用table方法更新table内筛选信息
    deleteFilterItem(key) {
      this.filterObject[key] = {};
      this.$refs.table.clearFilter([key]);
      this.updateFilterList();
      this.GetDoctorMatchList();
    },

    deleteAllFilter() {
      localStorage.removeItem('doctorMatch-filter');
      this.filterList = [];
      this.filterObject = {};
      this.$refs.table.clearFilter();
      setTimeout(() => {
        this.GetDoctorMatchList();
      });
    },

    searchOpts(property, val, formType, label) {
      console.log(property, val, formType, label);
      if (formType === 'input') {
        this.filterChange({ [property]: { label: val, value: val, formType } });
      } else {
        this.filterChange({ [property]: { label, value: val, formType } });
      }
    },

    setStorage() {
      const filterData = this.getStorage();
      filterData['doctor-match'] = this.filterObject;
      localStorage.setItem('doctorMatch-filter', JSON.stringify(this.filterObject));
    },
    getStorage() {
      const str = localStorage.getItem('doctorMatch-filter');
      return str ? JSON.parse(str) : {};
    },

    // pagination操作
    handleCurrentChange(val) {
      console.log(val);
      this.currentDataPage = val;
      this.GetDoctorMatchList();
    },
    handleSizeChange(val) {
      console.log(val);
      this.pageDataSize = val;
      this.GetDoctorMatchList();
    },

    // 失败原因
    handleViewLog(index, row) {
      console.log(index, row);
    },

    // 匹配结果,进入结果页
    gotoResult(index, row) {
      console.log(index, row);
      const query = {
        create_time: row?.create_time || this.$route.query.create_time,
        create_user: row?.create_user || this.$route.query.create_user,
        end_time: row?.end_time || this.$route.query.end_time,
        remark: row?.remark || this.$route.query.remark,
        status: row?.status || this.$route.query.status,
        task_id: row?.task_id || this.$route.query.task_id,
        file_name: row?.file_name || this.$route.query.file_name,
        file_url: row?.file_url || this.$route.query.file_url,
      };
      // sessionStorage.setItem('doctor-match-result', JSON.stringify(query));

      const routerData = this.$router.resolve({
        path: '/dashboard/doctor-match-result',
        query,
      });
      window.open(decodeURIComponent(routerData.href), '_blank');
    },

    // 获取任务列表
    GetDoctorMatchList() {
      const { remark, create_user, status } = this.filterObject;
      const statusNum = status ? this.filterObject.status.value : 0;
      let createUser = '';
      if (this.role !== 1) {
        createUser = this.userName;
      } else if (this.role === 1 && create_user) { // eslint-disable-line
        // eslint-disable-line
        createUser = create_user.value;
      }

      this.requestData = true;
      const params = {
        index: this.currentDataPage, // 页码
        page_size: this.pageDataSize, // 每页条数
        time_interval: this.tabMonth, // 时间区间
        remark: (remark && remark.value) || '', // 备注
        create_user: createUser,
        create_time_order: this.orderTime.createTime ? Number(this.orderTime.createTime) : 2, // 创建时间排序方案 0-不排序，1-正序，2-倒序
        end_time_order: this.orderTime.endTime ? Number(this.orderTime.endTime) : 0, // 结束时间排序方案 0-不排序，1-正序，2-倒序，二者同时出现时，优先使用create_time进行排序
        status: statusNum, // 任务状态：0-不做筛选、1-排队中、2-执行中、3-已完成、4-任务失败
      };
      apis.GetDoctorDataMatchTaskList(params).then(res => {
        if (res?.tasks) {
          const dataList = res.tasks;
          this.totalNum = res.total;

          this.dealDataLabel(dataList);
          this.tableData = dataList;
          console.log(this.tableData, 'tableData');
          setTimeout(() => {
            this.requestData = false;
          }, 500);
        }
      });
    },

    // 处理列表中下拉菜单的label值
    dealDataLabel(list) {
      const reg = /^[0-9a-zA-Z]/;
      list.forEach(ele => {
        Object.keys(ele).forEach(key => {
          if (this.propertyName.includes(key)) {
            if (reg.test(ele[key])) {
              ele[key] = this.getCodeLabel(key, ele[key]);
            }
          }
        });
      });
    },

    getCodeLabel(key, value) {
      const valObj = this.configLabel.filter(item => item.property === key);
      let valArr = [];
      if (JSON.stringify(valObj) !== '{}') {
        valArr = valObj[0].value;
      }
      let res = '';
      if (valArr.length > 0) {
        const arr = valArr.filter(item => item.code == value);
        if (arr.length > 0) {
          res = arr[0].label;
        }
      }
      return res;
    },

    hanldeSortChange(column) {
      console.log(column);
      if (column.order === 'descending') {
        this.orderTime =
          column.prop === 'create_time'
            ? { createTime: 2, endTime: '0' }
            : { endTime: 2, createTime: '0' };
      } else if (column.order === 'ascending') {
        this.orderTime =
          column.prop === 'create_time'
            ? { createTime: 1, endTime: '0' }
            : { endTime: 1, createTime: '0' };
      }
      this.GetDoctorMatchList();
    },

    getOptions() {
      const params = {
        dict_api_name: 'professional_title',
        dict_type: '1',
      };

      apis.GetDictOptionList(params).then(res => {
        if (res?.dict_list) {
          this.professionalOpts = res.dict_list.map(opt => {
            return {
              text: opt.label,
              label: opt.label,
              value: `${opt.code},${opt.label}`,
            };
          });
        }
      });
    },

    // 新建匹配
    hanldeCreateMatch() {
      this.createMatchDrawer = true;
    },

    // 抽屉-所属机构名称
    hanldeCheckInput(val) {
      // console.log(val);

      if (val.indexOf(',') > -1) {
        this.$message.error('不能输入英文逗号哦');
        this.ruleForm.drawer_type_name = '';
      }
    },

    // 抽屉-标准科室
    changeStandard(val) {
      console.log(val);
    },

    // 抽屉-专业职称
    changeProfessional(val) {
      console.log(val);
    },

    // 关闭医生数据匹配抽屉
    closeMatchDrawer() {
      this.$refs.doctorMatchForm.resetFields();
      this.$refs.doctorMatchForm.clearValidate();
      this.createMatchDrawer = false;
    },

    // 提交表单
    submitForm() {
      this.$refs.doctorMatchForm.validate(valid => {
        if (valid) {
          this.createDoctorMatch();
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },

    createDoctorMatch() {
      console.log(this.ruleForm);
      const {
        drawer_type_name,
        drawer_professional_title,
        drawer_standard_dept,
        drawer_remark,
      } = this.ruleForm;

      // 处理所属机构名称
      const orgName = this.delEndStr(drawer_type_name).split('\n');
      if (orgName.length > 100) {
        this.$message.error('单个匹配任务“所属机构名称”最多可查询100个');
        return;
      }
      const isMaxLength = orgName.every(item => item.length > 3);
      if (!isMaxLength) {
        this.$message.error('“所属机构名称”每个查询值不能少于4个中文字符');
        return;
      }

      // 处理标准科室
      const deptName = [];
      const deptCode = [];
      if (drawer_standard_dept.length > 0) {
        drawer_standard_dept.forEach(ele => {
          if (ele.length === 1) {
            deptName.push(ele.join(',').split(',')[1]);
            deptCode.push(ele.join(',').split(',')[0]);
          } else {
            const subName = [];
            const subCode = [];
            ele.forEach(e => {
              subName.push(e.split(',')[1]);
              subCode.push(e.split(',')[0]);
            });
            console.log(subName);
            console.log(subCode);
            deptName.push(subName.join('/'));
            deptCode.push(subCode.join('/'));
          }
        });
      }

      // 处理专业职称
      const proTitle = [];
      const proCode = [];
      if (drawer_professional_title.length > 0) {
        drawer_professional_title.forEach(ele => {
          proTitle.push(ele.split(',')[1]);
          proCode.push(ele.split(',')[0]);
        });
      }
      const params = {
        org_name: orgName, // 所属机构名称
        std_dept: deptName, // 标准科室，级联之间使用'/'分隔
        std_dept_code: deptCode, // 标准科室编码，需要和标准科室对应
        pro_title: proTitle, // 专业职称
        pro_title_code: proCode, // 专业职称编码，需要和专业职称对应
        remark: drawer_remark, // 备注
      };
      console.log(params);

      apis.CreateDoctorDataMatchTask(params).then(res => {
        if (res?.task_id) {
          this.$message.success('匹配任务已生成');
          this.closeMatchDrawer();
          this.GetDoctorMatchList();
        }
      });
    },

    // 递归删除'\n'
    delEndStr(str) {
      if (str.endsWith('\n')) {
        return this.delEndStr(str.substr(0, str.length - 1));
      }
      return str;
    },
  },
};
</script>

<style lang="scss" scoped>
@import './index.scss';
</style>
